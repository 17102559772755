
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EstimationClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import { baseUrl } from '@/config';
import SummaryCharts from '@/views/calculator/components/summaryCharts.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        SummaryCharts
    }
})
export default class Summary extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentConfigurationIdentifier: string;
    
    model: OM.SummaryVM = new OM.SummaryVM;
    loaded: boolean = false;

    created() {
        this.init();
    }
    
    init() {
        EstimationClient.summary(this.estimationIdentifier)
        .then(x => {
            this.model = x;
            this.loaded = true;

            store.state.estimationIdentifier = this.estimationIdentifier;
        })
    }

    downloadPDF() {
        EstimationClient.getPDFName(this.estimationIdentifier)
        .then(x => {
            this.$utils.downloadPdf(baseUrl + "api/Estimation/DownloadPDF", this.estimationIdentifier, x);
        })
    }

    exportCsv() {
        EstimationClient.exportCsv(this.estimationIdentifier)
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.text);
        })
    }

}
